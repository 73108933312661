//import Vue from "vue";
//import CapacityForm from "@/components/capacityform/capacityform.vue";
import store from "@/store/index.js";
import NeedCapacityForm from "@/components/needcapacityform/needcapacityform.vue";
import CapacityForm from "@/components/capacityform/capacityform.vue";
import {
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPopover,
  BSidebar,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  formatWithUnit,
  vehicleOptions,
  getVolumeWeight,
  stringIsNullOrEmpty,
} from "@/utils";
import ChatContainer from "@/components/chat/ChatContainer";
import { lightFormat, parseISO } from "date-fns";
import { nanoid } from "nanoid";
import { saveConversation } from "../../../services/api/conversation";
import { setFlagOnCapacity, getFlags } from "../../../services/api/favourite";
import { deleteCapacity } from "../../../services/api/capacity";
import AgreementForm from "@/components/agreementform/agreementform.vue";
import OfferForm from "@/components/offerform/offerform.vue";
import OfferList from "@/components/offerlist/offerlist.vue";
import Filterform from "@/components/filterform/filterform.vue";

//import { getCapacity } from "../../../services/api/capacity";

export default {
  name: "capacity-list",
  props: {
    company: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          type: "expand",
          hideFilter: true,
          slot: "expand",
          width: 40,
        },
        {
          title: "Firma",
          slot: "company",
          key: "company",
          align: "left",
          resizable: true,
          filterMethod(column, field, value, row) {
            if (value === undefined || value === null) {
              return true;
            }
            if (row.company.name && row.company.name !== "") {
              return row.company.name
                .toLowerCase()
                .includes(value.toLowerCase());
            } else {
              return value === row.company.name;
            }
          },
        },
        {
          title: "Fra",
          slot: "addressPickup",
          key: "addressPickup",
          align: "left",
          resizable: true,
          sortable: true,
          filterMethod(column, field, value, row) {
            if (!value || value === null || value === "") {
              return true;
            }
            if (
              row.addressPickup &&
              row.addressPickup.addressLine &&
              row.addressPickup.addressLine !== ""
            ) {
              return row.addressPickup.addressLine
                .toLowerCase()
                .includes(value.toLowerCase());
            } else {
              return value === row.addressPickup;
            }
          },
        },
        {
          title: "Til",
          slot: "addressDestination",
          key: "addressDestination",
          align: "left",
          resizable: true,
          sortable: true,
          filterMethod(column, field, value, row) {
            if (!value || value === null || value === "") {
              return true;
            }
            if (
              row.addressDestination &&
              row.addressDestination.addressLine &&
              row.addressDestination.addressLine !== ""
            ) {
              return row.addressDestination.addressLine
                .toLowerCase()
                .includes(value.toLowerCase());
            } else {
              return value === row.addressDestination;
            }
          },
        },
        {
          title: "Dato",
          key: "pickupDate",
          align: "center",
          sortable: true,
          slot: "date1",
          filterMethod(column, field, value, row) {
            if (!value || value === null || value === "") {
              return true;
            }
            if (
              row.pickupDate &&
              row.pickupDate != null &&
              row.pickupDate !== ""
            ) {
              return lightFormat(parseISO(row.pickupDate), "dd.MM.yyyy")
                .toLowerCase()
                .includes(value.toLowerCase());
            } else {
              return value === row.pickupDate;
            }
          },
        },
        {
          title: "Type",
          slot: "type",
          key: "type",
          align: "center",
          selectedType: "",
          headFilterSlot: "filterType",
        },
        {
          title: " ",
          key: "index",
          slot: "vehicle",
          align: "center",
          hideFilter: true,
        },
        {
          title: " ",
          slot: "flag",
          key: "flag",
          minWidth: 30,
          align: "right",
          headFilterSlot: "flagSwitch",
        },
        {
          title: " ",
          slot: "operations",
          key: "operations",
          minWidth: 150,
          align: "right",
          //hideFilter: true,
          headFilterSlot: "refreshButton",
        },
      ],
      data: [],
      modalCapacityShow: false,
      modalAgreementShow: false,
      modalOfferShow: false,
      formOfferShow: false,
      offerListShow: false,
      deleteCapacityOK: "",
      agreementSubmitEnabled: false,
      offerSubmitEnabled: false,
      showChat: false,
      selectedRow: {},
      selectedChatChannelId: "",
      conversationList: [],
      showFilterPane: false,
      code: `
    <BeeGridTable
        stripe 
        highlight-row
        :columns="columns"
        :data="data"
        :loading="true"
        ></BeeGridTable>
      `,
    };
  },
  computed: {
    capacityList() {
      return store.getters["capacity/capacityList"];
    },
    favouriteList() {
      return store.getters["capacity/favouriteList"];
    },
    chatChannelId() {
      if (this.selectedRow && this.selectedRow.id) {
        if (this.canEditCapacity(this.selectedRow)) {
          return this.selectedChatChannelId;
        }
        return (
          this.selectedRow.id + "#" + this.$store.getters["account/companyId"]
        );
      } else {
        return "";
      }
    },
    activeFilter() {
      if (store && store.getters["filter/currentFilter"]) {
        return JSON.stringify(store.getters["filter/currentFilter"]).length > 2;
      } else {
        return false;
      }
    },
  },
  components: {
    "need-capacity-form": NeedCapacityForm,
    "capacity-form": CapacityForm,
    "agreement-form": AgreementForm,
    "offer-form": OfferForm,
    "offer-list": OfferList,
    "filter-form": Filterform,
    "b-button": BButton,
    "b-badge": BBadge,
    "b-dropdown": BDropdown,
    "b-dropdown-item": BDropdownItem,
    "b-popover": BPopover,
    "b-sidebar": BSidebar,
    "b-tabs": BTabs,
    "b-tab": BTab,
    ChatContainer,
  },
  methods: {
    getVolumeWeight,
    lightFormat,
    parseISO,
    formatWithUnit,
    stringIsNullOrEmpty,
    getFlags,
    setFlagOnCapacity,
    deleteCapacity,
    confirmDelete(id) {
      this.deleteCapacityOK = "";
      this.$bvModal
        .msgBoxConfirm("Vil du slette denne ?", { centered: true })
        .then((value) => {
          this.deleteCapacityOK = value === true ? id : "";
          // TODO: Now run handleDelete()
          if (value === true) this.handleDelete(id);
        })
        .catch(() => {
          // An error occurred
        });
    },
    async handleDelete(id /*, index */) {
      let result = await this.deleteCapacity(id);
      if (result && result.data && result.data.success === true) {
        this.$noty.success("Registreringen ble slettet.");
        this.removeCapacityFromList(id);
      } else {
        this.$noty.info("Noe gikk galt ved slettingen");
      }
    },
    setConversationList(list) {
      this.conversationList = list;
      //this.$set("conversationList", list);
      if (list.length === 1) {
        this.selectedChatChannelId = list[0].conversationId;
      } else {
        this.selectedChatChannelId = "";
      }
    },
    canEditCapacity(capacity) {
      return !capacity.company
        ? false
        : capacity.company.id === store.getters["account/companyId"];
    },
    toggleFlag(row) {
      const flagged = !row.flagged ?? true;
      this.$set(row, "flagged", flagged);
      this.setFlaginDb(row, flagged);
    },
    async setFlaginDb(row, isFlagged) {
      await setFlagOnCapacity(row.id, isFlagged);
    },
    async handleOpenCapacity(row, index) {
      console.info(row, index);

      this.selectedRow = row; // TODO: Set row.id and fetch from API in modal
      //TODO: if not my company : this.showChat = false;
      this.showChat = true;
      this.modalCapacityShow = true;

      this.selectedRow = {
        id: row.id,
        type: row.type,
        company: row.company,
        offers: row.offers,
      };
      /* let rowDetails = await getCapacity(row.id);
      if (rowDetails && rowDetails.id === row.id) {
        this.selectedRow = rowDetails.data;
      } */
    },
    handleOpenContextMenu(row, index) {
      console.info(row, index);
      //TODO: Open some context menu...
    },
    async handleOpenAgreement(row, index) {
      console.info(row, index);
      this.selectedRow = row; // TODO: Set row.id and fetch from API in modal

      this.modalAgreementShow = true;
    },
    async toggleChatVisible() {
      this.showChat = !this.showChat;
      // TODO: if no Conversation - make one
      let conversationId = nanoid(10);
      /* let data =*/ await saveConversation({
        conversationId: conversationId,
      });
    },
    typeSelected(column, doSortAndFilter) {
      column.filterValue =
        column.selectedType === "-1" ? null : column.selectedType;
      doSortAndFilter();
    },
    updateListWithNewData(newData) {
      let list = store.getters["capacity/capacityList"];
      let index = list.findIndex((capacity) => capacity.id === newData.id);
      if (index >= 0) {
        list.splice(index, 1, newData);
      } else {
        list.push(newData);
      }
      store.dispatch("capacity/setCapacityList", list);
      this.modalCapacityShow = false;
    },
    removeCapacityFromList(id) {
      let list = store.getters["capacity/capacityList"];
      if (list && list.length) {
        list = list.splice();
        let index = list.findIndex((capacity) => capacity.id === id);
        if (index >= 0) {
          list.splice(index, 1);
          store.dispatch("capacity/setCapacityList", list);
        }
      }
    },
    async refreshListFromServer() {
      await store.dispatch("capacity/fetchCapacityList", {
        ...store.getters["filter/currentFilter"],
        company: this.company,
      });
      store.dispatch("capacity/fetchFavouriteList");
    },
    displayVehicleType(vehicleType) {
      if (!vehicleType || vehicleType === "") return " - ";
      let vehicle = vehicleOptions.find((option) => {
        return option.value === vehicleType;
      });
      return vehicle ? vehicle.text : "";
    },
    getVehicleImage(vehicleType) {
      if (!vehicleType || vehicleType === "") return "";
      let vehicle = vehicleOptions.find((option) => {
        return option.value === vehicleType;
      });
      return vehicle ? vehicle.url : "";
    },
    formatVolumeWeight(volumeWeight) {
      return volumeWeight && volumeWeight > 0
        ? " | " + volumeWeight + " dm<sup>3</sup>/l"
        : "";
    },
  },
  async mounted() {
    await this.refreshListFromServer();
  },
  watch: {
    capacityList(newList /*, oldList */) {
      this.data = newList;
    },
    favouriteList(flags) {
      console.info("watch flags: ", flags);
      this.data.forEach((row, index) => {
        const hasFlag = flags.find((flag) => flag.capacity === row._id);
        if (hasFlag && hasFlag.status === 1) {
          this.$set(this.data[index], "flagged", 1);
        }
      });
    },
  },
  created() {
    console.info("cpacity-list ??");
  },
};
